import React from "react";
import { Badge, Button, Container, Row, Col } from "reactstrap";

import ClientContactUs from "components/contact-us/ClientContactUs";
import ContactUs4 from "components/contact-us/ContactUs4";
import loadingSvgImage from '../../src/assets/img/ill/loading.svg';

function ContactUsPage() {
  const [iFrameLoadedSuccessfully, iFrameLoaderListener] = React.useState(false);

  let iFrameNotLoadingInfoMessage = null;
      if(!iFrameLoadedSuccessfully) {
        iFrameNotLoadingInfoMessage = (
          <div className="iframe-not-loading__div" style={{textAlign: "center"}}>
            <img src={loadingSvgImage} alt="loading"/>
            <p>Loading...</p>
          </div>
        );
      }

  let iFrameContents = (
    <div style={{overflowY: "hidden"}}>
          <iframe 
            src="https://docs.google.com/forms/d/e/1FAIpQLSdyHz0JOCPLeC2CAED_DIZFOwOBVTseIfPtd0GMD3UrMnJ2DA/viewform?embedded=true" 
            width="100%" 
            height="1000px" 
            frameborder="0" 
            marginheight="0" 
            marginwidth="0"
            onLoad={iFrameLoaderListener}
          >
          </iframe>
        </div>
  );

  return (
    <div className="mt-5 team-2">
      <Container fluid="true">
        <ClientContactUs/>
        {iFrameNotLoadingInfoMessage}
        {iFrameContents}
        
      </Container>
    </div>
  );
}

export default ContactUsPage;
