import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components

function Team2() {
  return (
    <>
      <div className="mt-5 mx-5 team-2">
        <Container fluid="true">
          <Row>
            <Col lg="4" md="6">
              <Card className="card-profile" data-image="profile-image">
                <CardHeader>
                  <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/services-images/medical-facilities.webp")}
                      ></img>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <h4 className="display-4 mb-0 pt-1">Medical Facilitiies</h4>
                  <p className="lead mt-5">We specialize in providing tailored transportation solutions for medical facilities, ensuring the seamless delivery of essential supplies.</p>
                  <div className="table-responsive">
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-profile" data-image="profile-image">
                <CardHeader>
                  <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/services-images/hospitals.webp")}
                      ></img>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <h4 className="display-4 mb-0 pt-1">Hospitals</h4>
                  <p className="lead mt-5">Hospitals rely on our dependable logistics services to maintain uninterrupted access to critical medical equipment and supplies.</p>
                  <div className="table-responsive">
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-profile" data-image="profile-image">
                <CardHeader>
                  <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/services-images/clinics.webp")}
                      ></img>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <h4 className="display-4 mb-0 pt-1">Clinics</h4>
                  <p className="lead mt-5">Clinics trust our services for the efficient transportation of medical supplies and patient records.</p>
                  <div className="table-responsive">
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6">
              <Card className="card-profile" data-image="profile-image">
                <CardHeader>
                  <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/services-images/laboratories.webp")}
                      ></img>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <h4 className="display-4 mb-0 pt-1">Laboratories</h4>
                  <p className="lead mt-5">Specialized transport services to handle delicate specimens and equipment with the utmost care and precision.</p>
                  <div className="table-responsive">
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-profile" data-image="profile-image">
                <CardHeader>
                  <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/services-images/pharmacies.webp")}
                      ></img>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <h4 className="display-4 mb-0 pt-1">Pharmacies</h4>
                  <p className="lead mt-5">Reliable delivery network to maintain a steady supply chain of medications and pharmaceutical products.</p>
                  <div className="table-responsive">
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-profile" data-image="profile-image">
                <CardHeader>
                  <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/services-images/general-moving.webp")}
                      ></img>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <h4 className="display-4 mb-0 pt-1">General Moving</h4>
                  <p className="lead mt-5">In addition to healthcare, we offer general moving services, providing a versatile solution for transporting goods with efficiency and reliability.</p>
                  <div className="table-responsive">
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6">
              <Card className="card-profile" data-image="profile-image">
                <CardHeader>
                  <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/services-images/airside-delivery.webp")}
                      ></img>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <h4 className="display-4 mb-0 pt-1">Airside Delivery</h4>
                  <p className="lead mt-5">Moemoe Couriers offers a unique Aside Delivery service that ensures personalized and secure handling of sensitive or valuable items, providing peace of mind for customers with specialized delivery needs.</p>
                  <div className="table-responsive">
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Team2;
