import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

function ClientNavbar(props) {
  const [collapseOpen, toggleCollapse] = React.useState(false);
  const toggleNavbar = () => toggleCollapse(!collapseOpen);

  const companyName = "MoeMoe Enterprises LLC";
  let navbarType = "";
  navbarType = "bg-white";
  const navbarFontSizeStyle = {fontSize: "1rem"};
  const collapseDisplayStyle = {display: "none"};

  return (
      <Navbar
        className={"navbar-main headroom " + navbarType}
        expand="lg"
        id="dark-navbar-main"
        style={{height: "75px"}}
      >
        <Container>
          <NavbarBrand className="mr-lg-5" style={navbarFontSizeStyle} to="/" tag={Link}>
            {companyName}
          </NavbarBrand>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Collapse
            id="client-navbar__collapse"
            style={collapseOpen ? null : collapseDisplayStyle}
            // toggler="#navbar_global"
            navbar
            isOpen={collapseOpen}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col 
                  className="collapse-brand" 
                  xs="6" 
                  onClick={toggleNavbar}
                >
                  <Link to="/">
                    <i className="fa-solid fa-house"></i>
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    onClick={toggleNavbar}
                  >
                    <span></span>
                    <span></span>
                  </button>
                </Col>
              </Row>
            </div>
            <Nav
              className="navbar-nav-hover align-items-lg-center ml-lg-auto mx-auto"
              navbar
            >
              <Link to="/about">
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    data-toggle="dropdown"
                    onClick={toggleNavbar}
                    role="button"
                    tag={NavLink}
                  >
                    <i className="ni ni-ui-04 d-lg-none"></i>
                    <span className="nav-link-inner--text" style={navbarFontSizeStyle}>About Us</span>
                  </DropdownToggle>
                </UncontrolledDropdown>
              </Link>
              

              <Link to="/services">
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    tag={NavLink}
                    data-toggle="dropdown"
                    onClick={toggleNavbar}
                    role="button"
                  >
                    <i className="ni ni-app d-lg-none"></i>
                    <span className="nav-link-inner--text" style={navbarFontSizeStyle}>Services</span>
                  </DropdownToggle>
                </UncontrolledDropdown>
              </Link>

              <Link to="/contact">        
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    tag={NavLink}
                    data-toggle="dropdown"
                    onClick={toggleNavbar}
                    role="button"
                  >
                    <i className="ni ni-single-copy-04 d-lg-none"></i>
                    <span className="nav-link-inner--text" style={navbarFontSizeStyle}>Contact Us</span>
                  </DropdownToggle>
                </UncontrolledDropdown>
              </Link>
            </Nav>
            <Nav className="nav navbar-right" navbar>
                <NavItem>
                  <NavLink target="_blank" href="https://www.facebook.com/">
                    <i className="fab fa-facebook-square fa-2xl"></i>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink target="_blank" href="https://twitter.com/">
                    <i className="fab fa-twitter fa-2xl"></i>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink target="_blank" href="https://www.instagram.com/moemoeenterprises/">
                    <i className="fab fa-instagram fa-2xl"></i>
                  </NavLink>
                </NavItem>
              </Nav>
          </Collapse>
        </Container>
      </Navbar>
  );
}

export default ClientNavbar;
