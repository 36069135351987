import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  Input,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Badge
} from "reactstrap";

function AboutUsPage() {
  return (
      <Container className="mt-5 mx-5" fluid="true">
        <Row className="align-items-center text-left">
          <Col lg="7" xs="12">
            <h1 className="display-3">
              Reliable Logistic & 
              <span className="text-primary">Transport Solutions.</span>
            </h1>
            <p className="lead pb-4">
            As Moemoe couriers, our focus is on specialized courier services tailored to medical institutions, hospitals, clinics, laboratories, pharmacies and healthcare providers. With a dedicated staff, we are 
            HIPAA certified. We guarantee fast and prompt delivery of medical devices, surgical instruments, provisions, samples, prescriptions and confidential documents. Moreover, our commitment extends beyond just transportation; We prioritize careful handling and adherence to strict security measures, ensuring the authenticity of any orders In addition to our reliable transportation services, we offer customized solutions to meet everyone’s specific needs. Whether it’s scheduling deliveries according to precise deadlines, using temperature-controlled transport to pick up sensitive medications, or providing real-time follow-up for added peace of mind, we strive to exceed our expectations. Our customer-centric approach ensures ease of communication and flexibility, allowing us to quickly adapt to the evolving needs of the dynamic healthcare landscape. Work with us for a solid ally in your medical planning journey.
            </p>
            {/* <Row className="row-input">
              <Col sm="8" xs="12">
                <Input
                  aria-label="Your email"
                  id="signupSrEmail"
                  name="email"
                  placeholder="Your email"
                  type="email"
                ></Input>
              </Col>
              <Col className="pl-lg-0" sm="4" xs="12">
                <Button block color="primary" type="submit">
                  More
                </Button>
              </Col>
            </Row> */}
          </Col>
          <Col className="pl-0" lg="5" xs="12">
            {/* <img
              alt="..."
              // className="ml-lg-5"
              style={{borderRadius: "1rem"}}
              src={require("assets/img/aboutus-image-1.png")}
              width="100%"
            ></img> */}
            
            <img
              alt="..."
              // className="ml-lg-5"
              style={{borderRadius: "1rem"}}
              src={require("assets/img/aboutus-image-2.png")}
              width="100%"
            ></img>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <div className="info">
              <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                <i className="ni ni-settings-gear-65"></i>
              </div>
              <h4 className="info-title text-uppercase text-primary">
                Transparent Best Price
              </h4>
              <p className="opacity-8">
              Our services come at the best prices in the market, ensuring affordability without compromising quality.
              </p>
              {/* <a
                className="text-primary"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                More about us{" "}
                <i className="ni ni-bold-right text-primary"></i>
              </a> */}
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
                <i className="ni ni-atom"></i>
              </div>
              <h4 className="info-title text-uppercase text-success">
                Real Time Tracking
              </h4>
              <p className="opacity-8">
              With our advanced technology, you can monitor your shipments in real-time, providing transparency and peace of mind throughout the delivery process.
              </p>
              {/* <a
                className="text-primary"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                Learn about our products{" "}
                <i className="ni ni-bold-right text-primary"></i>
              </a> */}
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                <i className="ni ni-world"></i>
              </div>
              <h4 className="info-title text-uppercase text-warning">
                Secure Cargo System
              </h4>
              <p className="opacity-8">
              We employ a secure cargo system to safeguard your valuable medical supplies, guaranteeing their integrity and safety during transit.
              </p>
              {/* <a
                className="text-primary"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                Check our documentation{" "}
                <i className="ni ni-bold-right text-primary"></i>
              </a> */}
            </div>
          </Col>
        </Row>
{/*         
               <Row className="align-items-center text-center justify-content-center">
          <Col className="pl-0" lg="5" xs="12">
            <h1 className="display-3">
              Morris Otieno
              <span className="text-primary">Founder</span>
            </h1>
            <img
              style={{borderRadius: "1rem"}}
              src={require("assets/img/aboutus-image-1.png")}
              height="auto"
              width="75%"
              
            ></img>
          </Col>
        </Row> */}

      </Container>


  );
}

export default AboutUsPage;
