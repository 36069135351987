import React from "react";

import Team2 from "components/teams/Team2.js";

function ServicesPage() {
  return (
    <Team2/>
  );
}

export default ServicesPage;
