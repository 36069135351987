import React from "react";

// reactstrap components
import { Badge, Button, Container, Row, Col } from "reactstrap";

import ClientContactUs from "components/contact-us/ClientContactUs.js";
import RequestQuoteForm from "views/examples/RequestQuoteForm.js";
import loadingSvgImage from '../../src/assets/img/ill/loading.svg';

function LandingPage() {
  const [iFrameLoadedSuccessfully, iFrameLoaderListener] = React.useState(false);

  let iFrameNotLoadingInfoMessage = null;
      if(!iFrameLoadedSuccessfully) {
        iFrameNotLoadingInfoMessage = (
          <div className="iframe-not-loading__div" style={{textAlign: "center"}}>
            <img src={loadingSvgImage} alt="loading"/>
            <p>Loading...</p>
          </div>
        );
      }

  let iFrameContents = (
    <div style={{overflowY: "hidden"}}>
        <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLSeZu8FLBwlt-5mQN5WInpS-SUqMb3MgP8Q2kBrB3virj9jC_A/viewform?embedded=true" 
          width="100%" 
          height="2000px" 
          frameborder="0" 
          marginheight="0" 
          marginwidth="0"
          onLoad={iFrameLoaderListener}
        >
        </iframe>
      </div>
  );
    
  return (
    <div 
      className="section section-hero section-shaped"
      style={{
          paddingTop: "0rem",
          paddingBottom: "0rem"
        }}
    >
      <div className="page-header" style={{minHeight: "35vh", color: "white"}}>
        <div className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/delivery-trucks.jpeg") + ")",
              height: "35vh",
              boxShadow: "inset 0 0 0 2000px rgba(136, 152, 170, 0.7)",
            }}
          >
          <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center " lg="12">
                    <p className="lead">
                      <b className="display-3">Swiftly delivered.</b>
                      <br></br><b className="display-3">Safely handled.</b>
                      <br></br>Professional, reliable, and cost-effective courier services.
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>

      </div>
      </div>
      <ClientContactUs />
      {/* <RequestQuoteForm /> */}
      {iFrameNotLoadingInfoMessage}
      {iFrameContents}

    </div>
  );
}

export default LandingPage;
