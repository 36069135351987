import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

function ClientContactUs() {
  return (
    <>
      <div className="contactus-3 mt-2">
        <Container fluid="true" className="">
          <Row>
            <Col lg="3" md="6" xs="12" className="contactus-hover">            
              <a href="tel:470-485-1503">
                <div className="info info-hover pb-0 pt-1">
                  <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                    <i className="ni ni-mobile-button"></i>
                  </div>
                  <h4 className="info-title">Free Reservation</h4>
                  <p className="font-weight-bold px-0">+1 (470) 485 - 1503</p> 
                </div>
              </a>
            </Col>
            <Col lg="3" md="6" xs="12" className="contactus-hover">
              <a href="mailto:info@moemoecouriers.com">
                <div className="info info-hover pb-0 pt-1">
                  <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                    <i className="ni ni-email-83"></i>
                  </div>
                  <h4 className="info-title">Email</h4>
                  <p className="font-weight-bold px-0">info@moemoecouriers.com</p>
                </div>
              </a>
            </Col>
            <Col lg="3" md="6" xs="12" className="contactus-hover">
              <div className="info info-hover pb-0 pt-1">
                <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                  <i className="ni ni-building"></i>
                </div>
                <h4 className="info-title">Office Hours</h4>
                <p className="font-weight-bold px-0">Mon-Sat</p>
                <p className="font-weight-bold px-0">8am - 6pm</p>
              </div>
            </Col>
            <Col lg="3" md="6" xs="12" className="contactus-hover">
              <div className="info info-hover pb-0 pt-1">
                <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                  <i className="ni ni-square-pin"></i>
                </div>
                <h4 className="info-title">Office Address</h4>
                <p className="font-weight-bold px-0">4936 Presidents Way, Suite 206 </p>
                <p className="font-weight-bold px-0">Tucker, GA 30084</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ClientContactUs;
